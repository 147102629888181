import React, { Suspense } from 'react';

import { AdminRoutes } from './routes/AdminRoutes';
import { LoggedInRoutes, NotLoggedInRoutes } from './routes/CommonRoutes';
import { AuthContext } from './shared/context/auth-context';
import { SnackbarContext } from './shared/context/snackbar-context';
import { CartContext } from './shared/context/cart-context';
import { DangerContext } from './shared/context/danger-context';
import { useAuth } from './shared/hooks/auth-hook';
import { useSnackbar } from './shared/hooks/snackbar-hook';
import { useCart } from './shared/hooks/cart-hook';
import { useDangerMode } from './shared/hooks/danger-hook';
import SnackBar from './shared/components/UIElements/SnackBar';
import theme from './shared/styles/theme';

import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';

const App: React.FC = () => {
  const maxWidth600 = useMediaQuery('(max-width: 600px)');
  const { token, login, logout, userId } = useAuth();
  const { snackbarState, open, close } = useSnackbar();
  const { productsNumber, load } = useCart();
  const { dangerMode, switchDangerMode } = useDangerMode();

  let routes: JSX.Element;
  if (token) {
    if (userId === process.env.REACT_APP_ADDMIN_ID) {
      // 管理者ログイン
      routes = (
        <DangerContext.Provider value={{ dangerMode, switchDangerMode }}>
          {AdminRoutes}
        </DangerContext.Provider>
      );
    } else {
      // 一般ユーザログイン
      routes = (
        <CartContext.Provider value={{ productsNumber, load }}>
          {LoggedInRoutes}
        </CartContext.Provider>
      );
    }
  } else {
    // 未ログイン
    routes = (
      <>
        {NotLoggedInRoutes}
      </>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        login: login,
        logout: logout,
      }}
    >
      <SnackbarContext.Provider value={{ open }}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Suspense fallback={
            <LinearProgress />
          }>
            <Box pt={maxWidth600 ? 7 : 8}>
              {routes}
            </Box>
          </Suspense>
          <SnackBar
            show={snackbarState.isShow}
            severity={snackbarState.severity}
            message={snackbarState.message}
            handleClose={close}
          />
        </ThemeProvider>
      </SnackbarContext.Provider>
    </AuthContext.Provider>
  );
}

export default App;
