import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { loggedInList, notLoggedInList } from './SideNavigationList';
import { AuthContext } from '../../context/auth-context';
import { SnackbarContext } from '../../context/snackbar-context';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    close: {
      marginTop: theme.spacing(0.5),
      marginLeft: theme.spacing(0.5),
      width: '50px',
      textAlign: 'right',
    },
    list: {
      width: 250,
    },
    fullList: {
      width: 'auto',
    },
    logout: {
      width: '100%',
      padding: 0,
    },
  }),
);

type Props = {
  open: boolean,
  onClose: () => void,
};

const SideNavigation: React.FC<Props> = ({ open, onClose }) => {
  const classes = useStyles();
  const history = useHistory();
  const auth = useContext(AuthContext);
  const snackbar = useContext(SnackbarContext);

  const handleLogout = () => {
    auth.logout();
    snackbar.open('success', 'ログアウトしました');
    history.push('/');
  };

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={onClose}
    >
      <IconButton
        aria-label="close"
        className={classes.close}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      <div
        className={classes.list}
        role="presentation"
        onClick={onClose}
        onKeyDown={onClose}
      >
        {auth.isLoggedIn && (
          <>
            {loggedInList}
            <Divider />
            <List>
              <Button
                onClick={handleLogout}
                className={classes.logout}
              >
                <ListItem button>
                  <ListItemIcon>
                    <img
                      src="/images/icons/logout.svg"
                      alt=""
                      style={{ width: '27px', height: '27px' }}
                    />
                  </ListItemIcon>
                  <ListItemText primary="ログアウト" />
                </ListItem>
              </Button>
            </List>
          </>
        )}
        {!auth.isLoggedIn && (
          <>
            {notLoggedInList}
          </>
        )}
      </div>
    </Drawer>
  );
}

export default SideNavigation;
