import { useState, useCallback } from 'react';

export const useDangerMode = () => {
  const [dangerMode, setDangerMode] = useState(false);

  // モード切替
  const switchDangerMode = useCallback(() => {
    setDangerMode(prevDangerMode => !prevDangerMode);
  }, []);

  return { dangerMode, switchDangerMode };
};
