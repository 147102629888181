import React from 'react';
import Badge from '@material-ui/core/Badge';
import { Theme, withStyles, createStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      right: 13.5,
      top: 8,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }),
)(Badge);

type Props = {
  items: number,
};

const ShoppingCartBadge: React.FC<Props> = ({ items }) => (
  <IconButton aria-label="cart" color="inherit">
    <StyledBadge badgeContent={items}>
      <img
        src="/images/icons/shopping-cart.svg"
        alt=""
        style={{ width: '50px', height: '30px' }}
      />
    </StyledBadge>
  </IconButton>
);

export default ShoppingCartBadge;
