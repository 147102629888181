import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const LOGGED_IN_LINKS = [
  { path: '/', imagePath: '/images/icons/home.svg', text: 'ホーム' },
  { path: '/aboutus', imagePath: '/images/icons/aboutus.svg', text: 'About Us' },
  { path: '/contact', imagePath: '/images/icons/contact.svg', text: 'お問い合わせ' },
  { path: '/faq', imagePath: '/images/icons/faq.svg', text: 'よくある質問' },
  { path: '/account', imagePath: '/images/icons/account.svg', text: 'マイページ' },
  { path: '/cart', imagePath: '/images/icons/cart.svg', text: 'カート' },
];

const NOT_LOGGED_IN_LINKS_1 = [
  { path: '/', imagePath: '/images/icons/home.svg', text: 'ホーム' },
  { path: '/aboutus', imagePath: '/images/icons/aboutus.svg', text: 'About Us' },
  { path: '/contact', imagePath: '/images/icons/contact.svg', text: 'お問い合わせ' },
  { path: '/faq', imagePath: '/images/icons/faq.svg', text: 'よくある質問' },
];

const NOT_LOGGED_IN_LINKS_2 = [
  { path: '/account/login', imagePath: '/images/icons/login.svg', text: 'ログイン' },
  { path: '/account/signup', imagePath: '/images/icons/signup.svg', text: '新規登録' },
];

export const loggedInList = (
  <List>
    {LOGGED_IN_LINKS.map(({ path, imagePath, text }, index) => (
      <Link
        to={path}
        component={RouterLink}
        underline="none"
        color="textPrimary"
        key={index}
      >
        <ListItem button>
          <ListItemIcon>
            <img
              src={imagePath}
              alt=""
              style={{ width: '27px', height: '27px' }}
            />
          </ListItemIcon>
          <ListItemText primary={text} />
        </ListItem>
      </Link>
    ))}
  </List>
);

export const notLoggedInList = (
  <>
    <List>
      {NOT_LOGGED_IN_LINKS_1.map(({ path, imagePath, text }, index) => (
        <Link
          to={path}
          component={RouterLink}
          underline="none"
          color="textPrimary"
          key={index}
        >
          <ListItem button>
            <ListItemIcon>
              <img
                src={imagePath}
                alt=""
                style={{ width: '27px', height: '27px' }}
              />
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        </Link>
      ))}
    </List>
    <Divider />
    <List>
      {NOT_LOGGED_IN_LINKS_2.map(({ path, imagePath, text }, index) => (
        <Link
          to={path}
          component={RouterLink}
          underline="none"
          color="textPrimary"
          key={index}
        >
          <ListItem button>
            <ListItemIcon>
              <img
                src={imagePath}
                alt=""
                style={{ width: '27px', height: '27px' }}
              />
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        </Link>
      ))}
    </List>
  </>
);
