import React, { memo } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';

const LINKS = [
  { text: 'お問い合わせ', path: '/contact' },
  { text: 'プライバシーポリシー', path: '/privacy' },
  { text: '特定商取引法に基づく表記', path: '/asct' },
];

function Copyright() {
  return (
    <Typography variant="body2">
      {'© 2020 '}
      <Link color="inherit" href="https://kei-shop.jp/">
        kei shop
      </Link>
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(12),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(2),
    color: theme.palette.grey[400],
    backgroundColor: theme.palette.grey[900],
  },
  container: {
    position: 'relative',
    '&::before': {
      position: 'absolute',
      top: -90,
      right: 10,
      width: 108,
      height: 50,
      content: `''`,
      display: 'block',
      backgroundImage: `url(${'/images/main/cat-footer.svg'})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    },
  },
  logo: {
    width: 150,
  },
  image: {
    width: '100%',
  },
  link: {
    marginBottom: theme.spacing(1),
  },
}));

function Footer() {
  const classes = useStyles();
  return (
    <footer className={classes.root}>
      <Container maxWidth="md" className={classes.container}>
        <Box
          display="flex"
          justifyContent="space-between"
        >
          <div className={classes.logo}>
            <img src="/images/main/logo.svg" alt="" className={classes.image} />
          </div>
          <Box
            display="flex"
            flexDirection="column"
          >
            {LINKS.map(({ text, path }, index) => (
              <Link
                to={path}
                component={RouterLink}
                color="inherit"
                className={classes.link}
                key={index}
              >
                <Typography variant="caption">{text}</Typography>
              </Link>
            ))}
          </Box>
        </Box>
        <Copyright />
      </Container>
    </footer>
  );
}

export default memo(Footer);
