import { useState, useCallback } from 'react';

import { useHttpClient } from './http-hook';
import { OrdersProducts } from '../types/order';

export const useCart = () => {
  const [productsNumber, setProdctsNumber] = useState(0);
  const { sendRequest } = useHttpClient();

  // カート内の商品の数をロード
  const load = useCallback(
    async (userId: string, token: string) => {
      try {
        const resposeData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/orders/${userId}/cart`,
          'GET',
          null,
          {
            Authorization: 'Bearer ' + token,
          }
        );
        const products: OrdersProducts = resposeData.order.products;

        if (products.length === 0) {
          setProdctsNumber(0);
        } else {
          // 商品配列の個数を合計する
          const productsNumber = products
            .map((product) => product.quantity)
            .reduce((a, b) => a + b);
          setProdctsNumber(productsNumber);
        }
      } catch (err) {}
    },
    [sendRequest]
  );

  return { productsNumber, load };
};
