import { useReducer, useCallback } from 'react';

type State = {
  isShow: boolean;
  severity: Severity;
  message: string;
};

type OpenAction = {
  type: 'OPEN_SNACKBAR';
  severity: Severity;
  message: string;
};

type CloseAction = {
  type: 'CLOSE_SNACKBAR';
};

type Actions = OpenAction | CloseAction;

export type Severity = 'success' | 'error' | 'warning' | 'info';

const snackbarReducer = (state: State, action: Actions) => {
  switch (action.type) {
    case 'OPEN_SNACKBAR':
      return {
        ...state,
        isShow: true,
        severity: action.severity,
        message: action.message,
      };
    case 'CLOSE_SNACKBAR':
      return {
        ...state,
        isShow: false,
      };
    default:
      return state;
  }
};

export const useSnackbar = () => {
  const [snackbarState, dispatch] = useReducer(snackbarReducer, {
    isShow: false,
    severity: 'success',
    message: '',
  });

  const open = useCallback((severity: Severity, message: string) => {
    dispatch({
      type: 'OPEN_SNACKBAR',
      severity,
      message,
    });
  }, []);

  const close = useCallback((event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch({
      type: 'CLOSE_SNACKBAR',
    });
  }, []);

  return { snackbarState, open, close };
};
