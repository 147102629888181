import React, { useContext, useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import SideNavigation from './SideNavigation';
import ShoppingCartBadge from '../UIElements/ShoppingCartBadge';
import { AuthContext } from '../../context/auth-context';
import { CartContext } from '../../context/cart-context';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    toolbar: {
      paddingRight: theme.spacing(1),
    },
    menuButton: {
      marginRight: theme.spacing(1),
    },
    logo: {
      width: 100,
      display: 'flex',
      alignItems: 'center',
    },
    image: {
      width: '100%',
    },
    title: {
      flexGrow: 1,
    },
  }),
);

export default function MenuAppBar() {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const cart = useContext(CartContext);
  const [openSideNav, setOpenSideNav] = useState(false);
  let ADDMIN_LOGIN_URL = process.env.REACT_APP_ADDMIN_LOGIN_URL;
  if (ADDMIN_LOGIN_URL === undefined) ADDMIN_LOGIN_URL = '/';

  const handleOpen = () => setOpenSideNav(true);
  const handleClose = () => setOpenSideNav(false);

  // カートの商品数をロード
  useEffect(() => {
    const loadCartItems = async (userId: string, token: string) => {
      try {
        await cart.load(userId, token);
      } catch (err) { }
    };
    if (auth.isLoggedIn) {
      loadCartItems(auth.userId, auth.token);
    }
  }, []);

  return (
    <div className={classes.root}>
      <AppBar color="primary">
        <Container maxWidth="lg" disableGutters>
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              className={classes.menuButton}
              onClick={handleOpen}
            >
              <MenuIcon />
            </IconButton>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <Link to="/" component={RouterLink} underline="none" color="inherit">
                <div className={classes.logo}>
                  <img
                    src="/images/main/logo.svg"
                    alt="kei shop"
                    className={classes.image}
                  />
                </div>
              </Link>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                {!auth.isLoggedIn && (
                  <>
                    <Link to="/account/signup" component={RouterLink} underline="none" color="inherit">
                      <Button color="inherit" size="large">
                        新規登録
                      </Button>
                    </Link>
                    <Link to="/account/login" component={RouterLink} underline="none" color="inherit">
                      <Button color="inherit" size="large">
                        ログイン
                      </Button>
                    </Link>
                  </>
                )}
                {auth.isLoggedIn && (
                  <Link
                    to="/cart"
                    component={RouterLink}
                    underline="none"
                    color="inherit"
                  >
                    <ShoppingCartBadge items={cart.productsNumber} />
                  </Link>
                )}
              </Box>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <SideNavigation
        open={openSideNav}
        onClose={handleClose}
      />
    </div>
  );
}
