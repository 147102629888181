import React from 'react';

import { Severity } from '../../hooks/snackbar-hook';

import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

const Alert: React.FC<AlertProps> = props => (
  <MuiAlert elevation={6} variant="filled" {...props} />
);

const useStyles = makeStyles((theme) => ({
  snackbar: {
    bottom: 16,
  },
}));

type Props = {
  show: boolean,
  severity?: Severity,
  message: string,
  handleClose: (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => void,
};

const SnackBar: React.FC<Props> = ({ show, severity, message, handleClose }) => {
  const classes = useStyles();
  return (
    <Snackbar
      open={show}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      className={classes.snackbar}
    >
      <Alert severity={severity} onClose={handleClose}>
        {message}
      </Alert>
    </Snackbar>
  );
}

export default SnackBar;
