import React, { lazy } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';

import MainNavigation from '../shared/components/Navigation/MainNavigation';
import Footer from '../shared/components/Navigation/Footer';
import GA from '../shared/utils/GoogleAnalytics';

// 共通（ログインに関わらない）
const Home = lazy(() => import('../other-pages/pages/Home'));
const AboutUs = lazy(() => import('../other-pages/pages/AboutUs'));
const Contact = lazy(() => import('../other-pages/pages/Contact'));
const FAQ = lazy(() => import('../other-pages/pages/FAQ'));
const ASCT = lazy(() => import('../other-pages/pages/ASCT'));
const Privacy = lazy(() => import('../other-pages/pages/Privacy'));
const Note = lazy(() => import('../other-pages/pages/Note'));
const FlowSilhouette = lazy(() => import('../other-pages/pages/FlowSilhouette'));
const FlowPhoto = lazy(() => import('../other-pages/pages/FlowPhoto'));
const ProductDetail = lazy(() => import('../product/pages/ProductDetail'));
const Works = lazy(() => import('../works/pages/Works'));

// ログイン時のみ
const Mypage = lazy(() => import('../account/pages/Mypage'));
const UpdateUser = lazy(() => import('../account/pages/UpdateUser'));
const Cart = lazy(() => import('../checkout/pages/Cart'));
const CheckoutLayout = lazy(() => import('../checkout/components/CheckoutLayout'));
const AddressForm = lazy(() => import('../checkout/pages/AddressForm'));
const PaymentForm = lazy(() => import('../checkout/pages/PaymentForm'));
const ReviewForm = lazy(() => import('../checkout/pages/ReviewForm'));
const FinishForm = lazy(() => import('../checkout/pages/FinishForm'));

// 未ログイン時のみ
const SignUp = lazy(() => import('../account/pages/Signup'));
const Login = lazy(() => import('../account/pages/Login'));
const ResetRequest = lazy(() => import('../account/pages/ResetRequest'));
const Reset = lazy(() => import('../account/pages/Reset'));
const AdminLogin = lazy(() => import('../admin/pages/AdminLogin'));

export const LoggedInRoutes = (
  <Router>
    {GA.init() && <GA.RouteTracker />}
    <MainNavigation />
    <Switch>
      {/* 共通ルート */}
      <Route path="/" exact>
        <Home />
      </Route>
      <Route path="/aboutus" exact>
        <AboutUs />
      </Route>
      <Route path="/contact" exact>
        <Contact />
      </Route>
      <Route path="/faq" exact>
        <FAQ />
      </Route>
      <Route path="/asct" exact>
        <ASCT />
      </Route>
      <Route path="/privacy" exact>
        <Privacy />
      </Route>
      <Route path="/note" exact>
        <Note />
      </Route>
      <Route path="/flow/silhouette" exact>
        <FlowSilhouette />
      </Route>
      <Route path="/flow/photo" exact>
        <FlowPhoto />
      </Route>
      <Route path="/products/:productId" exact>
        <ProductDetail />
      </Route>
      <Route path="/works/:productId" exact>
        <Works />
      </Route>

      {/* ログイン時のみルート */}
      <Route path="/account" exact>
        <Mypage />
      </Route>
      <Route path="/account/update" exact>
        <UpdateUser />
      </Route>
      <Route path="/cart" exact>
        <Cart />
      </Route>
      <Route path="/checkout/1" exact>
        <CheckoutLayout>
          <AddressForm />
        </CheckoutLayout>
      </Route>
      <Route path="/checkout/2" exact>
        <CheckoutLayout>
          <ReviewForm />
        </CheckoutLayout>
      </Route>
      <Route path="/checkout/3" exact>
        <CheckoutLayout>
          <PaymentForm />
        </CheckoutLayout>
      </Route>
      <Route path="/checkout/finish" exact>
        <CheckoutLayout>
          <FinishForm />
        </CheckoutLayout>
      </Route>
      <Redirect to="/" />
    </Switch>
    <Footer />
  </Router>
);

export const NotLoggedInRoutes = (
  <Router>
    {GA.init() && <GA.RouteTracker />}
    <MainNavigation />
    <Switch>
      {/* 共通ルート */}
      <Route path="/" exact>
        <Home />
      </Route>
      <Route path="/aboutus" exact>
        <AboutUs />
      </Route>
      <Route path="/contact" exact>
        <Contact />
      </Route>
      <Route path="/faq" exact>
        <FAQ />
      </Route>
      <Route path="/asct" exact>
        <ASCT />
      </Route>
      <Route path="/privacy" exact>
        <Privacy />
      </Route>
      <Route path="/note" exact>
        <Note />
      </Route>
      <Route path="/flow/silhouette" exact>
        <FlowSilhouette />
      </Route>
      <Route path="/flow/photo" exact>
        <FlowPhoto />
      </Route>
      <Route path="/products/:productId" exact>
        <ProductDetail />
      </Route>
      <Route path="/works/:productId" exact>
        <Works />
      </Route>

      {/* 未ログイン時のみルート */}
      <Route path="/account/signup" exact>
        <SignUp />
      </Route>
      <Route path="/account/login" exact>
        <Login />
      </Route>
      <Route path="/account/password/reset" exact>
        <ResetRequest />
      </Route>
      <Route path="/account/password/reset/:token" exact>
        <Reset />
      </Route>
      <Route path={process.env.REACT_APP_ADDMIN_LOGIN_URL} exact>
        <AdminLogin />
      </Route>
      <Redirect to="/account/login" />
    </Switch>
    <Footer />
  </Router>
);
