import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'メイリオ',
      'Meiryo',
      'Hiragino Kaku Gothic Pro',
      'ヒラギノ角ゴ Pro W3',
      'ＭＳ Ｐゴシック',
      'sans-serif',
    ].join(','),
    fontSize: 14,
    h1: {
      fontSize: '1.3rem',
    },
    h2: {
      fontSize: '1.15rem',
    },
    h3: {
      fontSize: '1.125rem',
    },
    h4: {
      fontSize: '1rem',
    },
    h5: {
      fontSize: '1rem',
    },
    h6: {
      fontSize: '1rem',
    },
    body1: {
      fontSize: '0.875rem',
    },
    body2: {
      fontSize: '0.8rem',
    },
  },
  palette: {
    background: {
      paper: '#fff',
      default: '#f9f8f4',
    },
    primary: {
      light: '#ff8a50',
      main: '#ff0000',
      dark: '#c41c00',
      contrastText: '#fafafa',
    },
    secondary: {
      light: '#a98274',
      main: '#795548',
      dark: '#4b2c20',
      contrastText: '#fff',
    },
  },
  props: {
    MuiCheckbox: {
      color: 'primary',
    },
    MuiRadio: {
      color: 'primary',
    },
    MuiSwitch: {
      color: 'primary',
    },
  },
});

export default theme;
