import React, { lazy } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';

const AdminLayout = lazy(() => import('../admin/components/AdminLayout'));
const AdminHome = lazy(() => import('../admin/pages/AdminHome'));
const Products = lazy(() => import('../admin/pages/Products'));
const NewProduct = lazy(() => import('../admin/pages/NewProduct'));
const AdminWorks = lazy(() => import('../admin/pages/Works'));
const NewWorks = lazy(() => import('../admin/pages/NewWorks'));
const UpdateProduct = lazy(() => import('../admin/pages/UpdateProduct'));
const Customers = lazy(() => import('../admin/pages/Customers'));
const Orders = lazy(() => import('../admin/pages/Orders'));
const Order = lazy(() => import('../admin/pages/Order'));
const Contacts = lazy(() => import('../admin/pages/Contacts'));
const AdminContact = lazy(() => import('../admin/pages/Contact'));
const TopSliders = lazy(() => import('../admin/pages/TopSliders'));
const NewTopSliders = lazy(() => import('../admin/pages/NewTopSliders'));
const Notices = lazy(() => import('../admin/pages/Notices'));
const NewNotice = lazy(() => import('../admin/pages/NewNotice'));
const ProductDetail = lazy(() => import('../product/pages/ProductDetail'));

export const AdminRoutes = (
  <Router>
    <AdminLayout>
      <Switch>
        <Route path="/admin" exact>
          <AdminHome />
        </Route>
        <Route path="/admin/products" exact>
          <Products />
        </Route>
        <Route path="/admin/products/new" exact>
          <NewProduct />
        </Route>
        <Route path="/admin/products/:productId" exact>
          <ProductDetail />
        </Route>
        <Route path="/admin/products/update/:productId" exact>
          <UpdateProduct />
        </Route>
        <Route path="/admin/works" exact>
          <AdminWorks />
        </Route>
        <Route path="/admin/works/new" exact>
          <NewWorks />
        </Route>
        <Route path="/admin/customers" exact>
          <Customers />
        </Route>
        <Route path="/admin/orders" exact>
          <Orders />
        </Route>
        <Route path="/admin/orders/:orderId" exact>
          <Order />
        </Route>
        <Route path="/admin/contacts" exact>
          <Contacts />
        </Route>
        <Route path="/admin/contacts/:contactId" exact>
          <AdminContact />
        </Route>
        <Route path="/admin/sliders" exact>
          <TopSliders />
        </Route>
        <Route path="/admin/sliders/new" exact>
          <NewTopSliders />
        </Route>
        <Route path="/admin/notices" exact>
          <Notices />
        </Route>
        <Route path="/admin/notices/new" exact>
          <NewNotice />
        </Route>
        <Redirect to="/admin" />
      </Switch>
    </AdminLayout>
  </Router>
);
